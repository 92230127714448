import React from "react";
// Customizable Area Start
import { Box, Button, Card,  Grid, TextField, Typography, Breadcrumbs } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import AppNavigation from '../../../components/src/AppNavigation.web';
import { I18n } from "../../../components/src/languageJson/i18n";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";

// Customizable Area End

import AddServiceController, { Props } from "./AddserviceController.web";
import { checked, unchecked } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AddService extends AddServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {value}
      </Typography>

    );
  }

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <div style={{display:'flex'}}>
        <AppNavigation />
        <div style={{flex:1, backgroundColor:"#F1F5FC", padding:'50px 0 50px 20px', height:'142vh'}}>
          <ThemeProvider theme={theme}>
            <Box style={{ paddingRight: "20px" }}>
              <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("serviceManagement"), I18n.t("addNewService")]}/>
            </Box>
            <div style={{ maxWidth: "100vw", display: "flex", justifyContent: "center",padding:"0 0 0 0" }}>
              <Box style={{backgroundColor:"#527FE2",padding:"24px 8px 24px 8px",width:"100%",borderRadius:"0 0 0 24px",}}>
              <Typography style={{fontFamily:"Poppins",fontWeight:700,
              fontSize:"1.5rem",color:"ffffff",textAlign:"center"}} >
                  Add New Service</Typography>
              </Box>
          
            </div>
            <Box style={{ maxWidth: "100vw", display: "flex",flexDirection:"column",gap:"1.5rem", justifyContent: "center",padding:"1.5rem 1.5rem 1.5rem 0" }}>

              <Card style={{ maxWidth: "100vw", display: "flex",flexDirection:"column",gap:"1.5rem", 
              justifyContent: "center",padding:"1.5rem 1.5rem" ,borderRadius:"12px",border:"solid 1px #E2E8F0"}}>
                <Grid item sm={12} xs={12} md={12}  >
                  <InputField
                    data-test-id="EnglishName"
                    fullWidth
                    
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Service English Name*"
                    value={this.state.EngServiceName}
                    error={this.state.fieldErrors.EnglishName}
                    onChange={(event) => {
                      const value = event.target.value;
                      this.onValueChange("EngServiceName", value);
                    }}
                
                  />
                  {this.state.fieldErrors.EnglishName && this.getErrorMessageNonFormik(this.state.fieldErrors.EnglishName)}
                </Grid>
                <Grid item sm={12} xs={12} md={12}  >
                  <InputField
                    data-test-id="ArabicName"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Service Arabic Name*"
                    value={this.state.ArabicServiceName}
                    error={this.state.fieldErrors.ArabicName}
                    onChange={(event) => {
                      const value = event.target.value;
                      this.onValueChange("ArabicServiceName", value);
                    }}
                
                  />
                  {this.state.fieldErrors.ArabicName && this.getErrorMessageNonFormik(this.state.fieldErrors.ArabicName)}
                </Grid>
                <Grid item sm={12} xs={12} md={12}  >
                  <InputField
                    data-test-id="ServicePrice"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Service Price*"
                    value={this.state.SerrvicePrice}
                    error={this.state.fieldErrors.ServicePrice}
                    onChange={(event) => {
                      const value:any = event.target.value;
                      this.onValueChange("SerrvicePrice", value)}}
                
                  />
                  {this.state.fieldErrors.ServicePrice && this.getErrorMessageNonFormik(this.state.fieldErrors.ServicePrice)}
                </Grid>
                <Grid item sm={12} xs={12} md={12}>
                  <Box data-test-id="checkbox" style={{display:"flex",alignItems:"center",gap:"12px",cursor:"pointer"}} onClick={this.handleCHeckPrice}>
                <img style={{width:"20px",height:"20px"}} src={this.state.PickupChecked?checked:unchecked} alt="checkbox" />
                <Typography style={{fontFamily:"Poppins",fontSize:"1rem",color:"#000000"}}>Pick up and Delivery</Typography>
                </Box>
                </Grid>
                <Grid item sm={12} xs={12} md={12}   >
                  <InputField
                    data-test-id="PickupFees"
                    disabled={!this.state.PickupChecked}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Pickup and Delivery Fees"
                    value={this.state.PickupFees}
                    onChange={(event) => {
                      const value = event.target.value;
                      this.onValueChange("PickupFees", value)}}
                
                  />
              {this.state.fieldErrors.PickupFees && this.getErrorMessageNonFormik(this.state.fieldErrors.PickupFees)}

                </Grid>
            
              </Card>
            </Box>

            <Box style={{display:"flex",justifyContent:"center",margin:"2rem"}}> 
            <AddServiceBtn data-test-id="addService" onClick={this.validateAddService}>Add Service</AddServiceBtn>
            </Box>
          </ThemeProvider>
        </div>
      </div>
      

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start


const InputField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "56px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 8px", 
    height: "100%", 
    boxSizing: "border-box", 
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px 0 0 8px",
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },
 
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#6EE7B7",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#6EE7B7",
    },
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px)": {
    minWidth: "unset",
    maxWidth: "unset",
  },
});

const AddServiceBtn = styled(Button)({
  color: "white",
  borderRadius: "8px",
  backgroundColor: "#527FE2",
  textTransform: "none",
  fontFamily: "Poppins",
  fontWeight:700,
  fontSize: "16px",
  padding: "10px 16px 10px 16px",
  maxWidth: "306px",
  width:"306px",
  lineHeight:"24px",
  height: "44px",
  "&:hover": {
    color: "white",
    backgroundColor: "#527FE2",
  },
  "@media(max-width:600px)": {
    fontSize:"18px",
    padding: "10px 16px 10px 16px",
  },
});

  
  
  

// Customizable Area End
