// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const userImage = require("../assets/user.png");
export const filterImage = require("../assets/filter.png");
export const editButton = require("../assets/edit_button_icon.png");
export const cameraImg = require("../assets/camera.png");
export const empIcon = require("../assets/emp_icon.png");
export const idIcon = require("../assets/id_icon.png");
export const departmentIcon = require("../assets/department_icon.png");
export const branchIcon = require("../assets/branch_icon.png");
export const positionIcon = require("../assets/position_icon.png");
export const reportIcon = require("../assets/report_icon.png");
export const locationIcon = require("../assets/location_icon.png");
export const emailIcon = require("../assets/email_icon.png");

export const redEmpIcon = require("../assets/red_emp_icon.png");
export const redIdIcon = require("../assets/red_id_icon.png");
export const redDepartmentIcon = require("../assets/red_department_icon.png");
export const redBranchIcon = require("../assets/red_branchicon.png");
export const redPositionIcon = require("../assets/red_position_icon.png");
export const redReportIcon = require("../assets/red_report_icon.png");
export const redEmailIcon = require("../assets/red_email_icon.png");
export const checkMark = require("../assets/carbon_checkmark.png")
export const cancelIcon = require("../assets/cancel.png");
export const noData = require("../assets/no_data.png");

export const imgOne = require("../assets/img_1.png");
export const imgTwo = require("../assets/img_2.png");
export const imgThree = require("../assets/img_3.png");
export const imgFour = require("../assets/img_4.png");
export const imgFive = require("../assets/img_5.png");
export const imgSix = require("../assets/img_6.png");
export const imgSeven = require("../assets/img_7.png");
export const imgEight = require("../assets/img_8.png");
// Customizable Area End