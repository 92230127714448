import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Card,
  Breadcrumbs
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { FilterList, SearchOutlined, ChatBubbleOutline } from "@material-ui/icons";
import { I18n } from "../../../components/src/languageJson/i18n";
import { tickets } from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from '@material-ui/lab/Pagination';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Link } from "react-router-dom";
import AppNavigation from '../../../components/src/AppNavigation.web';


const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const subOptionsTitle = [`${I18n.t("all")}`, `${I18n.t("service")}`, `${I18n.t("suggestion")}`, `${I18n.t("complain")}`];
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   optionsTitle = [
    { title: I18n.t("tickets"),icon:  <img src={tickets} height={32} width={32} />  },
    { title: I18n.t("warranties"), icon: <CheckCircleIcon height={32} width={32}/> },
    { title: I18n.t("chat"), icon: <ChatBubbleOutline height={32} width={32}/> },
    { title: I18n.t("reviews"), icon: <CalendarViewDayIcon height={32} width={32}/> },
  ];

  useStyles = {
    statusProgess:{
      height:"22px",
      width:"111px",
      fontSize:"12px",
      fontWeight: 700,
      fontFamily:"Inter",
      lineHeight:"18px",
      borderRadius:"40px",
      color:"#527FE2",
      backgroundColor:"#BFDBFE",
      textAlign:"center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    statusPendding:{
      height:"22px",
      width:"111px",
      fontSize:"12px",
      fontWeight: 700,
      fontFamily:"Inter",
      lineHeight:"18px",
      borderRadius:"40px",
      color:"#D97706",
      backgroundColor:"#FEF3C7",
      textAlign:"center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    statusFinished:{
      height:"22px",
      width:"111px",
      fontSize:"12px",
      fontWeight: 700,
      fontFamily:"Inter",
      lineHeight:"18px",
      borderRadius:"40px",
      color:"#527FE2",
      backgroundColor:"#BFDBFE",
      textAlign:"center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    tableRow: {
      margin: "5px 0 5px 0",
      display: "flex",
      flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
      width:"100%",
    },
    tableHead: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 700,
      color: "#FFFFFF",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      whiteSpace:"nowrap" as "nowrap",
    },
    tableBody: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      textAlign:"center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    tableBodyStatus:{
      width: "100%",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      display:"flex",
      gap:20,
      textAlign:"center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    subOption:{ 
      fontSize: "14px", lineHeight: "24px", 
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"), 
      fontWeight: 400,
      direction: this.getValueBasedOnLanguage("rtl", "ltr")
    },
    options:{ 
      fontSize: "14px", 
      lineHeight: "24px", 
      fontWeight: 700, 
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"), 
    },
    navLinks:{
      fontWeight:400,
      fontSize:"14px",
      fontFamily:this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      color:"#000000",
      textDecoration:"none",
    },
  }

  adornmentStyle = {
    order: this.getValueBasedOnLanguage(1, 0),
    marginRight: this.getValueBasedOnLanguage(8, 0),
    marginLeft: this.getValueBasedOnLanguage(0, 8),
  };

  CardBox = styled(Card)({
    overflowY: "auto", height: "60vh",
    backgroundColor: "fffffff",
    border: "1 px solid #CBD5E1",
    width: "100%", 
    margin: "auto", 
    borderRadius: "1.5rem",
    display:"flex",
    justifyContent:"space-between",
    flexDirection:"column",
    "@media (max-width:560px)": {
      overflow: "scroll",
      display: "flex"
    },

  });

  TableCellHead = styled(TableCell)({
    fontWeight: 700,
    border: "none", 
    backgroundColor: "#527FE2", 
    color: "ffffff",
    fontFamily: "Poppins",
    fontSize: "1rem", padding: "8px 24px",
    height: "64px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  });

  TableCellBody = styled(TableCell)({
    border: "none", 
    height: '64px', 
    fontFamily: "Poppins", 
    fontSize: "16px", 
    padding: "8px 24px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flex:1,
    minWidth:"100px",
    whiteSpace:"nowrap"
  });

  InputField = styled(TextField)({
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    width: "100%",
    backgroundColor: "#FFFFFF",
    textAlign: this.getValueBasedOnLanguage("right", "left"),
    borderRadius: this.getValueBasedOnLanguage("0 25px 25px 0", "25px 0 0 25px"),
    '& .MuiInputBase-input::placeholder': {
      color: '#94A3B8',
      opacity: 1,
    },
    "& .MuiInputBase-root": {
      height: "44px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0 8px",
      height: "100%",
      boxSizing: "border-box",
      direction: this.getValueBasedOnLanguage("rtl", "ltr")
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderRadius: this.getValueBasedOnLanguage("0 25px 25px 0", "25px 0 0 25px"),
    },
    "& .MuiInputBase-input[type='date']": {
      lineHeight: "19.2px",
      textTransform: "uppercase",
      color: "#334155",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "18px",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#6EE7B7",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#6EE7B7",
      },
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
    },
    "@media (max-width:990px)": {
      minWidth: "unset",
      maxWidth: "unset",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
    },
  });

  AddServiceBtn = styled(Button)({
    color: "white",
    borderRadius: "8px",
    backgroundColor: "#527FE2",
    textTransform: "none",
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    fontWeight: 700,
    fontSize: "16px",
    padding: "10px 16px 10px 16px",
    maxWidth: "306px",
    width: "306px",
    lineHeight: "24px",
    height: "44px",
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    "&:hover": {
      color: "white", backgroundColor: "#527FE2",
    },
    "@media(max-width:600px)": {
      fontSize: "18px", padding: "10px 16px 10px 16px",
    },
  });

  boxStyle = (index: any) => ({
    borderRadius: "6px",
    border: this.state.selected === index ? "0.4px solid #2D8CFF" : "0.4px solid #64748B",
    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
    backgroundColor: this.state.selected === index ? "#2D8CFF" : "#F1F5FC",
    color: this.state.selected === index ? "#FFFFFF" : "#64748B",
    cursor: "pointer",
    alignItems: "center",
  });
  
  BoxFirst = styled(Box)({
    paddingLeft: this.getValueBasedOnLanguage("", "20px"),
    paddingRight: this.getValueBasedOnLanguage("20px", ""),  
    height: "56px",
    width: "227px",
    display: "flex",
    gap: "14px",
    "@media (max-width: 430px)":{
      width: "80px",
      gap: "4px",
      flexWrap:"wrap",
      padding:5,
      textAlign:"center",
      justifyContent:"center",
      paddingLeft:0,
      paddingRight:0,
    },
  })

  boxSubStyle = (index: any) => ({
    height: "36px",
    borderRadius: 50,
    border: this.state.selectedSubOption === index ? "1px solid #A5B4FC" : "",
    display: "flex",
    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
    backgroundColor: this.state.selectedSubOption === index ? "#DBEAFE" : "",
    color: this.state.selectedSubOption === index ? "#527FE2" : "#64748B",
    cursor: "pointer",
    gap: "40px",
    alignItems: "center",
    padding: "4px 20px",
  });

  boxSub = styled(Box)({
    display: "flex", 
    flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"), 
    gap: "14px", 
    width:"100%",
    justifyContent: "center",
    "@media (max-width: 430px)":{
      gap: "4px"
    },
    "@media (max-width: 380px)":{
      gap: "2px"
    },
  });

  BreadcrumbsCust = styled(Breadcrumbs)({
    display: 'flex', 
    flexWrap: 'nowrap', 
    '& .MuiBreadcrumbs-ol': {
      display: 'flex',
      flexWrap: 'nowrap',
    },
  });

  statusCheck = (statusCheck: string) => {
    switch (statusCheck) {
      case "pending":
        return this.useStyles.statusPendding;
      case "In Progress":
        return this.useStyles.statusProgess;
      case "finished":
        return this.useStyles.statusFinished;
      default:
        return {};
    }
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={{ display: 'flex'}}>
        <AppNavigation />
        <div style={{flex:1, overflow: 'hidden'}}>
          <ThemeProvider theme={theme}>
            <div style={{backgroundColor:'#F1F5FC', padding:'50px 20px'}}>
              <Box style={{alignSelf:"center",width:"10%"}}>
                <this.BreadcrumbsCust 
                  separator={this.getValueBasedOnLanguage(<NavigateBeforeIcon />, <NavigateNextIcon />)}
                  style={{color:"#000000",height:"9px",width:"5px",whiteSpace:"nowrap"}}
                  >
                  <Link href="" to={""} style={this.useStyles.navLinks}>{I18n.t("home")}</Link>
                  <Link href="" to={""} style={{...this.useStyles.navLinks, fontWeight: '700', color: '#3f51b5'}}>{I18n.t("tickets")}</Link>
                </this.BreadcrumbsCust>
              </Box>
              <div style={{ marginTop: 40, width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Box style={{ display: "flex", flexDirection: "column", gap: "16px", justifyContent: "center",  alignItems:"center" }}>
                  <this.boxSub> 
                    {this.optionsTitle.map((option, index) => (
                      <this.BoxFirst key={index}
                        style={this.boxStyle(index)}
                        onClick={() => this.handleOptionSelect(index)}>
                        {option.icon}
                        <Typography style={this.useStyles.options}>
                          {option.title}
                        </Typography>
                      </this.BoxFirst>
                    ))}
                  </this.boxSub>

                  <this.boxSub>
                    {subOptionsTitle.map((option, index) => (
                      <Box key={index}
                        style={this.boxSubStyle(index)}
                        onClick={() => this.handleSubOptionSelect(index)}>
                        <Typography style={this.useStyles.subOption}>
                          {option}
                        </Typography>
                      </Box>
                    ))}
                  </this.boxSub>
                </Box>

                <div style={{ display: "flex", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"), justifyContent: "center", alignSelf: "center",width: "80%", padding: "1.5rem", borderRadius: "0.5rem", marginTop: "1rem", }}>
                  <div className="btncontainer" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", width: "60%", flexDirection: this.getValueBasedOnLanguage("row-reverse", "row") }}>

                    <this.InputField
                      type="search"
                      id="search"
                      data-test-id="searchinput"
                      onChange={this.handleSearchInput}
                      value={this.state.getSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" style={this.adornmentStyle}
                          >
                            <SearchOutlined style={{ color: "#94A3B8" }} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={I18n.t("search")}
                      variant="outlined"
                    />
                  </div>
                  <Button data-test-id="searchField"
                    variant="outlined"
                    style={{
                      fontSize: '18px', fontWeight: 700, textTransform: "none", backgroundColor: "#527FE2", borderRadius: this.getValueBasedOnLanguage("25px 0 0 25px", "0 25px 25px 0"),
                      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins")
                    }}>
                    <FilterList style={{ color: "ffffff" }} />
                  </Button>
                </div>
              </div>
              <this.CardBox >
                <div className="table" style={{ display: "flex", justifyContent: "center", marginTop: "1rem" ,maxWidth:"100%",overflowX:"auto"}}>
                  <Table style={{ width: "95%", height: "4rem",overflow:"scroll" }} >
                    <TableHead>
                      <TableRow style={this.useStyles.tableRow}>
                        <this.TableCellHead style={{ width: "100%", borderRadius: this.getValueBasedOnLanguage("0 0 24px 0", "0 0 0 24px"), fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins") }}>
                          {I18n.t("clientID")}
                        </this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("customer")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("category")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("creationDate")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("responseTime")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("department")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("assignee")}</this.TableCellHead>
                        <this.TableCellHead style={this.useStyles.tableHead}>{I18n.t("status")}</this.TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.ticketData.map((row: any, index: any) => (

                        <TableRow style={this.useStyles.tableRow} key={index}>
                          <this.TableCellBody style={this.useStyles.tableBody}>
                            # {row.attributes.sender_id}
                          </this.TableCellBody>

                          <this.TableCellBody style={this.useStyles.tableBody}>
                            {row.attributes.sender_full_name}
                          </this.TableCellBody>

                          <this.TableCellBody style={this.useStyles.tableBody}>
                            {row.attributes.request_type}
                          </this.TableCellBody>

                          <this.TableCellBody style={this.useStyles.tableBody}>
                            {this.dateFormat(row.attributes.created_at)}
                          </this.TableCellBody>

                          <this.TableCellBody style={this.useStyles.tableBody}>
                          </this.TableCellBody>

                          <this.TableCellBody style={this.useStyles.tableBody}>
                          </this.TableCellBody>

                          <this.TableCellBody style={this.useStyles.tableBody}>
                          </this.TableCellBody>

                          <this.TableCellBody style={this.useStyles.tableBodyStatus}>
                            <Typography style={this.statusCheck(row.attributes.status)}>
                              {row.attributes.status}
                            </Typography>
                            <MoreVertIcon />
                          </this.TableCellBody>
                        </TableRow>
                      ))}

                    </TableBody>
                  </Table>
                
                </div>
                <Box style={{display:"flex",justifyContent:"center"}}>
                <Pagination></Pagination>
                </Box>

              </this.CardBox>
              <Box data-test-id="goToAddService"
                style={{ display: "flex", justifyContent: "center", margin: "3rem" }}>
                <this.AddServiceBtn>{I18n.t("mangeTicketSettings")}</this.AddServiceBtn>
              </Box>
            </div>
          </ThemeProvider>
        </div>
      </div>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tableTitleStyle: {
    alignSelf: "flex-start",
    marginTop: 20,
  },
  tableBtnStyle: {
    marginRight: 10,
  },
  inputStyle: {
    marginTop: 15,
    width: 350,
    maxWidth: "100%",
  },
};
// Customizable Area End
