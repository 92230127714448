import React, { Component } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
    user, logout, payment, Back, inventory, organ, Survey
    , Appoin, help, peopl, role, service, notifi, desk, helpPlusImage
} from "./imgsrc"

class AppNavigation extends Component {

    getSelectedItemFromStorage() {
        return localStorage.getItem('selectedItem') || 'Dashboard';
    }

    state = {
        selectedItem: this.getSelectedItemFromStorage(),
    };


    handleMenuItemClick = (item: string) => {
        this.setState({ selectedItem: item });
        localStorage.setItem('selectedItem', item);
    };
    
    render() {
        return (
            <Box style={{ width: 318, height: '100vh', backgroundColor: '#fff', paddingTop: 20, borderRight: '1px solid #E2E8F0', fontFamily: '"Poppins", sans-serif' }}>
                <Box style={{ width: 270, padding: 20 }}>
                    {/* Logo */}
                    <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: 16, borderBottom: '1px solid #E2E8F0', }}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>

                            <img src={helpPlusImage} alt="Help Plus"
                                style={{
                                    width: '184px',
                                    height: '91px'
                                }} />
                        </Typography>
                    </Box>
                    {/* Menu Items */}
                    <List>
                        <List style={{ borderBottom: '1px solid #E2E8F0' }}>
                            <ListItem button style={{ padding: '8px 12px 8px 12px' }}>
                                <img src={Back} alt=""
                                    style={{ width: '24px', paddingRight: '15px' }} />
                                <ListItemText primary="Back" />
                            </ListItem>
                            <ListItem 
                                button
                                style={{
                                    color: this.state.selectedItem === 'Dashboard' ? '#527FE2' : 'inherit',
                                    fontWeight: this.state.selectedItem === 'Dashboard' ? 1000 : 'normal', // Apply font weight if selected
                                    fontFamily: this.state.selectedItem === 'Dashboard' ? '"Poppins", sans-serif' : 'inherit',
                                }}
                                onClick={() => this.handleMenuItemClick('Dashboard')} 
                            >
                                <img src={desk} alt=""
                                    style={{ width: '24px', paddingRight: '15px' }} />
                                <ListItemText
                                    primary="Dashboard"
                                    
                                />
                            </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Payment Admin' ? '#527FE2' : 'inherit',
                                    fontWeight: this.state.selectedItem === 'Payment Admin' ? 1000 : 'normal', // Apply font weight if selected
                                    fontFamily: this.state.selectedItem === 'Payment Admin' ? '"Poppins", sans-serif' : 'inherit',
                                }}
                                onClick={() => this.handleMenuItemClick('Payment Admin')} 
                            >
                                <img src={payment} alt=""
                                    style={{ width: '24px', paddingRight: '15px', cursor: 'pointer' }} />

                                <ListItemText primary="Payment Admin" />
                            </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Email and Notification' ? '#527FE2' : 'inherit',
                                    fontWeight: this.state.selectedItem === 'Email and Notification' ? 1000 : 'normal', // Apply font weight if selected
                                    fontFamily: this.state.selectedItem === 'Email and Notification' ? '"Poppins", sans-serif' : 'inherit',
                                }}
                                onClick={() => this.handleMenuItemClick('Email and Notification')} 
                            >
                                <img src={notifi} alt=""
                                    style={{ width: '24px', paddingRight: '15px' }} />
                                <ListItemText primary="Email and Notification" />
                            </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Role and Permission' ? '#527FE2' : 'inherit',
                                    fontWeight: this.state.selectedItem === 'Role and Management' ? 1000 : 'normal', // Apply font weight if selected
                                    fontFamily: this.state.selectedItem === 'Role and Management' ? '"Poppins", sans-serif' : 'inherit',
                                }}
                                onClick={() => this.handleMenuItemClick('Role and Permission')} 
                            >
                                <img src={role} alt=""
                                    style={{ width: '24px', paddingRight: '15px', }} />
                                <ListItemText primary="Role and Permission" />
                            </ListItem>
                        </List>



                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'Service Management' ? '#527FE2' : 'inherit',
                                fontWeight: this.state.selectedItem === 'Service Management' ? 1000 : 'normal', // Apply font weight if selected
                                fontFamily: this.state.selectedItem === 'Service Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Service Management')}
                            component={Link} to="/Catalogue"
                        >
                            <img src={service} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="Service Management" />
                        </ListItem>

                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'People Management' ? '#527FE2' : 'inherit',
                                fontWeight: this.state.selectedItem === 'People Management' ? 1000 : 'normal', // Apply font weight if selected
                                fontFamily: this.state.selectedItem === 'People Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('People Management')}
                            component={Link} to="/AccountGroups"
                        >
                            <img src={peopl} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="People Management" />
                        </ListItem>

                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'Request Management' ? '#527FE2' : 'inherit',
                                fontWeight: this.state.selectedItem === 'Request Management' ? 1000 : 'normal', // Apply font weight if selected
                                fontFamily: this.state.selectedItem === 'Request Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Request Management')}
                            component={Link} to="/RequestManagement"
                        >
                            <img src={peopl} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="Request Management" />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Help AI Management' ? '#527FE2' : 'inherit',
                                fontWeight: this.state.selectedItem === 'Help AI Management' ? 1000 : 'normal', // Apply font weight if selected
                                fontFamily: this.state.selectedItem === 'Help AI Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Help AI Management')}
                        >
                            <img src={help} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="Help AI Management" />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Survey Management' ? '#527FE2' : 'inherit',
                                fontWeight: this.state.selectedItem === 'Survey Management' ? 1000 : 'normal', // Apply font weight if selected
                                fontFamily: this.state.selectedItem === 'Survey Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Survey Management')}
                        >
                            <img src={Survey} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="Survey Management" />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Appointment Management' ? '#527FE2' : 'inherit',
                                fontWeight: this.state.selectedItem === 'Appointment Management' ? 1000 : 'normal', // Apply font weight if selected
                                fontFamily: this.state.selectedItem === 'Appointment Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Appointment Management')}
                        >
                            <img src={Appoin} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="Appointment Management" />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Organization Management' ? '#527FE2' : 'inherit',
                                fontWeight: this.state.selectedItem === 'Organization Management' ? 1000 : 'normal', // Apply font weight if selected
                                fontFamily: this.state.selectedItem === 'Organization Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Organization Management')}
                        >
                            <img src={organ} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="Organization Management" />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Inventory Management' ? '#527FE2' : 'inherit',
                                fontWeight: this.state.selectedItem === 'Inventory Management' ? 1000 : 'normal', // Apply font weight if selected
                                fontFamily: this.state.selectedItem === 'Inventory Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Inventory Management')}
                        >
                            <img src={inventory} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="Inventory Management" />
                        </ListItem>

                        <ListItem button component={Link} to="/">
                            <img src={logout} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primary="Logout" style={{ color: '#ff0000' }} />
                        </ListItem>
                    </List>

                    {/* User Profile */}
                    <Link to="/EducationalUserProfile" style={{textDecoration:"none", color:"inherit"}}>
                        <Box style={{ display: 'flex', alignItems: 'center', padding: 16, position: 'absolute', cursor:'pointer', textDecoration:"none", color:"inherit" }}>
                            <img src={user} alt=""
                                style={{ width: '56px', paddingRight: '15px' }} />
                            <Box>
                                <Typography variant="body1" style={{ fontWeight: 'bold' }}>Ahmed Salem</Typography>
                                <Typography variant="body2" color="textSecondary">SAMSUNG</Typography>
                            </Box>
                        </Box>
                    </Link>
                </Box>
            </Box>
        );
    }
}

export default AppNavigation;
