import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TextField,
  Breadcrumbs,
  Typography,
  InputAdornment,
  Dialog,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  SelectProps,
  Avatar
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { DeleteOutline, Search } from "@material-ui/icons";
import { cancelIcon, checkMark, editButton, filterImage, noData } from "./assets";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Pagination } from "@material-ui/lab";
import { I18n } from "../../../components/src/languageJson/i18n";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppNavigation from '../../../components/src/AppNavigation.web';
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  conditionalRenderObject(condition:any,trueStmt:any,falseStmt:any){
    return condition ? condition[trueStmt] : falseStmt
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainBox>
        <AppNavigation />
        <StyledBox>
        <div style={{ paddingBottom: '50px' }}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Typography color="inherit">
            {I18n.t("home")}
            </Typography>
            <Typography color="primary" style={{ fontWeight: '700'}}>
            {I18n.t("peopleManagement")}
            </Typography>
          </Breadcrumbs>
        </div>

        <div className="search_wrapper" style={{ textAlign: 'center'}}>
        <SearchBox className="addclass">
          <TextField
            variant="outlined"
            placeholder="Search"
            data-test-id="search_id"
            className="search-bar searchInput"
            value={this.state.searchValue} // Bind the state to the TextField
            onChange={this.handleChangeSearch} 
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
          <div onClick={this.handleClickOpen} className="filterBtn"><img src={filterImage} alt="filter_img" /></div>
        </SearchBox>
        </div>

        <MainTableDiv>
          <TableMain className="mainoftable">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRowMain>
                  <TableCell style={{ borderBottomLeftRadius: '20px', width: '200px' }}>{I18n.t("employeeName")}</TableCell>
                  <TableCell >{I18n.t("employeeID")}</TableCell>
                  <TableCell>{I18n.t("department")}</TableCell>
                  <TableCell>{I18n.t("position")}</TableCell>
                  <TableCell>{I18n.t("reportingTo")}</TableCell>
                  <TableCell></TableCell>
                </TableRowMain>
              </TableHead>
              <TableBody>
                {this.state.setEmpData && Array.isArray(this.state.setEmpData) && 
                  this.state.setEmpData.map((item: any, index: number) => (
                    <TableRowBody className="checkhereclass" key={index}>
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                          <div><Avatar alt="emp_img" src={item.attributes.image} /></div>
                          <div><Typography>{item.attributes.name}</Typography></div>
                        </div>
                      </TableCell>
                      <TableCell>#{item.attributes.employee_id}</TableCell>
                      <TableCell>{item.attributes.department}</TableCell>
                      <TableCell>{item.attributes.designation}</TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                          <div> <Avatar alt="emp_img" src={item.attributes.reporting_to_image} /></div>
                          <div><Typography>{item.attributes.reporting_to != null ? item.attributes.reporting_to : "--"}</Typography></div>
                        </div>
                      </TableCell>
                      <TableCell><div style={{ display: 'flex', gap: '15px' }}><DeleteOutline data-test-id='delete_emp' onClick={() => this.handleOpenDelete(item.attributes.id,item.attributes.name)} style={{ color: 'red' }} /> <img data-test-id='edit_emp' onClick={() => this.handleNavigation1(item.attributes.id)} src={editButton} alt="edit_button" /> </div></TableCell>
                    </TableRowBody>
                  ))
                }
              </TableBody>
               {this.state.setEmpData.length == 0 && (
                    <NoData className="testingClass">
                      <img src={noData} alt="no_data" />
                      <Typography className="noEmpText">No employees found for the selected filter.</Typography>
                    </NoData>
                  )}
            </Table>
          </TableMain>

            {
              this.state.setEmpData.length != 0 && (
                <div className="setPagination">
                  <Pagination
                    count={Math.ceil(this.state.setCount / 12)}
                    boundaryCount={1}
                    siblingCount={1}
                    data-test-id='pagination_id'
                    page={this.state.page} // Bind the current page state
                    onChange={this.handlePageChange} // Handle page change event
                  />
                  <div style={{ textAlign: 'end' }}>
                    <Typography className="setCount">
                      {`${(this.state.page - 1) * 12 + 1}-${Math.min(this.state.page * 12, 48)} of ${this.state.setCount} results`}
                    </Typography>
                  </div>
                </div>
              )
            }
        </MainTableDiv>

        <AddButton>
          <Button onClick={this.handleNavigation} className="addBtn">{I18n.t("addNewEmployee")}</Button>
        </AddButton>

        <FilterDialog className="setDynamic" onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.filterModalOpen}>
          <div className="mainFilter">
            <div>
              <Typography className="filterText">Filters</Typography>
            </div>
            <div className="innerFilter">
              <div>
                <Typography className="clearText" onClick={this.clearFilter}>Clear all</Typography>
              </div>
              <div onClick={this.handleClose}>
                <img src={cancelIcon} alt="cancel_icon" />
              </div>
            </div>
          </div>
          <br />

          <AccordionBox className="setAccroedion">
              <Accordion key={1} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">Department</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <FormControl variant="outlined" fullWidth>
                  <SelectMain
                    style={{ borderRadius: '10px' }}
                    label="Select here"
                    name="department"
                    data-test-id="department_id"
                    displayEmpty
                    value={this.state.setDepartment}
                    onChange={(event) => this.handleChange("department", event)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          'borderRadius': '10px'
                        },
                      },
                      style: {
                        inset: '60px 0 0'
                      }
                    }}
                    renderValue={(value) => {
                      const selectedDepartment = this.state.setDepartmentData?.find(
                        (item: any) => item.id === value
                      );
                      return (
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            color: "currentColor",
                            opacity: this.conditionalRenderObject(selectedDepartment,"1.22","0.42"),
                          }}
                        >
                          {selectedDepartment ? selectedDepartment.attributes.name : "Department"}
                        </Box>
                      );
                    }}
                    input={<OutlinedInput />}
                  >
                    {this.state.setDepartmentData &&
                      this.state.setDepartmentData.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.attributes.name}
                        </MenuItem>
                      ))}
                  </SelectMain>
                </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion key={2} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">Position</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl variant="outlined" fullWidth>
                    <SelectMain
                      style={{ borderRadius: '10px' }}
                      label="Select here"
                      name="position"
                      data-test-id='position_id'
                      displayEmpty
                      value={this.state.setPosition}
                      onChange={(event) => this.handleChange("position",event)}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            'borderRadius': '10px'
                          },
                        },
                        style: {
                          inset: '60px 0 0'
                        }
                      }}
                      renderValue={(value) => {
                        const selectedDepartment = this.state.setPositionData?.find(
                          (item: any) => item.id === value
                        );
                        return (
                          <Box
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              color: "currentColor",
                              opacity: this.conditionalRenderObject(selectedDepartment,"1.22","0.42"),
                            }}
                          >
                            {selectedDepartment ? selectedDepartment.name : "Position"}
                          </Box>
                        );
                      }}
                      input={<OutlinedInput />}
                    >
                      {
                      this.state.setPositionData && this.state.setPositionData.map((item: any, index: number) =>
                      (
                        <MenuItem key={item.id} value={item.id} > {item.name} </MenuItem>
                      )
                      )
                    }
                    </SelectMain>
                  </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion key={3} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">Reporting To</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl variant="outlined" fullWidth>
                    <SelectMain
                      style={{ borderRadius: '10px' }}
                      label="Select here"
                      name="reporting_To"
                      data-test-id='reporting_id'
                      value={this.state.setReporting}
                      onChange={(event) => this.handleChange("reporting",event)}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: {
                            'borderRadius': '10px'
                          },
                        },
                        style: {
                          inset: '60px 0 0'
                        }
                      }}
                      renderValue={(value: SelectProps['value']) => (
                        <Box
                          style={{ display: "flex", flexWrap: "wrap", color: 'currentColor', opacity: '0.42' }}>
                          {value
                            ? value as string
                            : 'Reporting To'}
                        </Box>
                      )}
                      input={<OutlinedInput />}
                    >
                      {
                        this.state.setReportingData && this.state.setReportingData.map((item: any, index: number) =>
                        (
                          <MenuItem style={{
                            borderBottom: this.conditionalRenderObject(index === this.state.setReportingData.length - 1, "none", "1px solid #d4d2cd"),
                          }} key={index} value={item?.id}>{item?.attributes?.name}</MenuItem>
                        )
                        )
                      }
                    </SelectMain>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
          </AccordionBox>

          <div className="setApplyBtn">
            <button onClick={this.applyFilter} className="applyBtn">Apply</button>
          </div>

          </FilterDialog>

          <DeleteEmpDialog
            open={this.state.deleteEmpModal}
            onClose={this.handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="adddynamic"
          >
            <div>
              <div style={{ textAlign: 'center' }}>
              <DeleteOutline style={{ color: 'red', width: '80px', height: '80px' }} />
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px', }}>
                <Typography className="setEmpText">Are You sure you want to delete this employee?</Typography>
                <Typography className="innerText">This action will delete all data of this and it cannot be retrieved</Typography>
              </div>
              <div className="flexBtn">
                <button className="deleteEmpButton" onClick={this.deleteEmployeeCall}>Delete</button>
                <button onClick={this.handleCloseDelete}className="cancleButton">Cancel</button>
              </div>
            </div>
          </DeleteEmpDialog>

          <SuccessDialog
            open={this.state.successDialog}
            onClose={this.successDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="adddynamic"
          >
            <div>
              <div style={{ textAlign: 'center' }}>
                <img src={checkMark} alt="check_mark" />
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px', }}>
                <Typography className="setEmpText">Employee removed successfully.</Typography>
              </div>
              <div style={{ textAlign: 'center' }}>
                <button className="viewEmpButton" onClick={this.successDelete}>Cancel</button>
              </div>
            </div>
          </SuccessDialog>
        </StyledBox>
      </MainBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  height: '100vh',
  display:'flex',
});

const StyledBox = styled(Box)({
  flex: 1,
  background: '#F1F5FC',
  padding: '50px 20px',
  height: '142vh'
});

const AccordionBox = styled(Box)({
  width: '100%',
  '& .accText':{
    fontWeight: 700,
    color:'#64748B',
  },
  '& .MuiPaper-root':{
    '& .MuiButtonBase-root':{
      padding: '0px 20px !important'
    },
    '& .MuiCollapse-root':{
      background: '#F8FAFC',
      '& .MuiCollapse-wrapper':{
        '& .MuiCollapse-wrapperInner':{
          '& .MuiAccordionDetails-root':{
            padding: '0px 28px'
          }
        }
      }
    }
  }
});

const SearchBox = styled(Box)({
  textAlign: 'center',
  position: 'relative',
  display: 'inline-block',
  '& .searchInput':{
    '& .MuiInputBase-root':{
      borderRadius: '4px',
      borderTopLeftRadius: '40px',
      background: 'white',
      borderBottomLeftRadius: '40px',
      '& .MuiOutlinedInput-input':{
        padding: '10px 0px',
        width: '500px',
        '@media (max-width: 600px)': {
          width: '100%'
        }
      },
    }
  },
  '& .filterBtn':{
    position: 'absolute',
    top: '0px',
    right: '-15px',
    padding: '0px 10px',
    background: '#527FE2',
    borderTopRightRadius: '40px',
    borderBottomRightRadius: '40px',
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  }
});
const TableMain = styled(TableContainer)({
  '& .MuiTable-root': {
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          borderBottom: 'none',
          fontFamily: 'poppins'
        }
      }
    },
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-stickyHeader': {
          backgroundColor: '#527FE2',
          color: 'white',
          fontWeight: 700
        }
      }
    }
  }
});

const TableRowMain = styled(TableRow)({
  '& .MuiTableCell-root':{
    fontFamily: 'poppins'
  }
})

const TableRowBody = styled(TableRow)({
  '& .MuiTableCell-root':{
    '& .MuiTypography-root':{
    fontFamily: 'poppins'
    }
  }
})

const MainTableDiv = styled(Box)({
  marginTop: '20px',
  padding: '20px',
  border: '1px solid #c8c8c8',
  borderRadius: '20px',
  background: 'white',
  '& .setPagination':{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '10px',
    position: 'relative',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        },
    '& .setCount':{
      position: 'absolute',
      right: '0', 
      top:'50%', 
      color: '#64748B',
      transform: 'translateY(-50%)',
      '@media (max-width: 600px)': {
        position: 'static',
        marginTop: '10px',
        transform: 'none',
        textAlign: 'center'
        }
    }
  },
});

const AddButton = styled(Box)({
  marginTop: '30px',
  textAlign: 'center',
  '& .addBtn': {
    padding: '10px 80px',
    border: 'none',
    background: '#527FE2',
    borderRadius: '8px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom:'60px',
    fontFamily: 'poppins'
  }
});

const FilterDialog = styled(Dialog)({
  position: 'relative',
  '& .setApplyBtn': {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '20px'
  },
  '& .applyBtn':{
    padding: '10px 30px',
    background: '#527FE2',
    color: 'white',
    fontWeight: 700,
    borderRadius: '8px',
    border: 'none'
  },
  '& .mainFilter':{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: '1px solid #dddad6'
    },
  '& .innerFilter': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px'
  },
  '& .filterText':{
    fontWeight: 700
  },
  '& .clearText': {
    fontWeight: 400,
    color:'#527FE2',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  '& .MuiDialog-container':{
    position: 'absolute',
    right: '0',
    '& .MuiPaper-root':{
      width: '444px',
      height: '100%',
      maxHeight: '100%',
      boxShadow: 'none',
      margin: '0',
      '@media (max-width: 500px)': {
        width: '350px',
      },
    },
  }
});

const NoData = styled(Box)({
  position: 'relative',
  left: '120%',
  '& .noEmpText':{
    fontWeight: 700,
    textAlign: 'center'
  }
});

const SelectMain = styled(Select)({
  '& .MuiSelect-outlined.MuiSelect-outlined': {
    backgroundColor: 'transparent',
  },
})

const DeleteEmpDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none',
      borderRadius: '10px'
    }
  },
  '& .setEmpText':{
    fontWeight:700
  },
  '& .innerText':{
    fontWeight:400,
    color: '#94A3B8',
    marginTop: '10px'
  },
  '& .flexBtn':{
    display: 'flex',
    gap: '10px',
    alignItems: 'end',
    justifyContent: 'end'
  },
  '& .deleteEmpButton':{
    padding: '10px 20px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: 'red',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700
  },
  '& .cancleButton': {
    // width: '100%',
    padding: '10px 20px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700
  }
});

const SuccessDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none',
      borderRadius: '10px'
    }
  },
  '& .setEmpText':{
    fontWeight:700
  },
  '& .viewEmpButton': {
    // width: '100%',
    padding: '10px 160px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700
  }
});
// Customizable Area End
